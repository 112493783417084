<template>
    <div class="page-container">
        <div v-if="!isScanEnabled" class="page-wrapper">
            <div class="ticket-image-wrapper">
                <img :src="theming.boardingPassImageUrl">
            </div>
            <div class="upload-title">{{ $t('boarding.title') }}</div>
            <div>
                <span class="description"> {{ $t('boarding.description') }}</span>
            </div>
            <boarding-pass-upload />
            <span class="description">or</span>
            <b-button
                :disabled="isScanEnabled"
                :style="{backgroundColor: theming.boardingButtonColor, color: theming.boardingButtonTextColor}"
                class="scan-button"
                @click="openScanner"
            >{{ $t('boarding.scanButtonText') }}
            </b-button>
        </div>
        <div v-else class="camera-wrapper">
            <camera-scanner
                @close-scanner="closeScanner"
            />
        </div>
    <!--        <b-button :disabled="isScanEnabled" @click="openScanner">Toggle Scan</b-button>-->
    </div>
</template>

<script>
    import CameraScanner from '@/components/camera-scanning/CameraScanner.vue'
    import BoardingPassUpload from '@/components/BoardingPassUpload.vue'
    import boardingPassService from '@/services/boarding-pass-service'

    export default {
        name: 'TaskScanBoardingPass',
        components: { CameraScanner, BoardingPassUpload },
        props: {
            taskIndex: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                isScanEnabled: false,
                boardingPassContent: null,
                uploadPercentage: 0
            }
        },
        computed: {
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            theming() {
                return this.$store.getters.getTheming
            }
        },
        methods: {
            openScanner() {
                this.isScanEnabled = true
            },
            async closeScanner(content) {
                await boardingPassService.uploadDecodedPass(content)

                this.isScanEnabled = false
                await this.$router.push({ name: 'My Flights' })
            }
        }
    }
</script>

<style scoped>
.page-container {
    display: flex;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding-top: 2.5rem;
    max-width: 400px;
    width: 90%;
    margin: 0 auto;
    font-weight: bold;
}

.upload-title {
    font-family: CFAstyStd, sans-serif;
    font-weight: 700;
    font-size: 2rem;
    color: #fff;
    text-align: center;
}

.description {
    font-family: CFAstyStd, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #fff;
}

.ticket-image-wrapper {
    margin-top: 1rem;
}

.ticket-image-wrapper img {
    max-width: 88px;
}

.scan-button {
    border: none;
    height: 46px;
    max-width: 285px;
    width: 100%;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    border-radius: 6px;
}

.camera-wrapper {
    margin-top: 15vh;
    border: 2px solid #0af;
    height: 50vh;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
}

.camera-container {
    flex: 1;
}

@media screen and (min-width: 992px) {
    .camera-wrapper {
        border: 3px solid #0af;
        margin: 15vh auto;
        height: unset;
    }
}
</style>
